@font-face {
  font-family: "Outfit";
  src: local("Outfit Bold"), local("Outfit-Bold"),
    url("../fonts/Outfit-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Outfit";
  src: local("Outfit Medium"), local("Outfit-Medium"),
    url("../fonts/Outfit-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Outfit";
  src: local("Outfit Regular"), local("Outfit-Regular"),
    url("../fonts/Outfit-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

// html {
//   scroll-behavior: smooth;
// }

td svg {
  width: 50px;
}

.fc .fc-event {
  margin: 0;
}

.text {
  &-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.test {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  .nav-item {
    flex: 0 0 auto;
  }
}

.field-item {
  width: 100%;
  padding: 0 3.25rem;

  .field-value {
    padding-left: 5rem;
    font-weight: 500;
    width: 100%;
  }
}

.white-space-pre-line {
  white-space: pre-line;
}

.bq-note-text {
  white-space: pre-line;
}

.form-group {
  white-space: pre-line;
}

.form-btn-submit {
  display: flex;
  justify-content: center;
}

#test-select {
  svg {
    display: inline-flex;
    font-size: 1.2em;
    width: auto;
  }
}

.rdt_TableHeadRow {
  .rdt_TableCol {
    .rdt_TableCol_Sortable div {
      padding: 0.6rem 0;
      white-space: pre-line;
    }

    &:last-child {
      .rdt_TableCol_Sortable {
        justify-content: center !important;
      }

      > * {
        overflow: visible;
      }
    }
  }
}

.table-actions {
  tbody td:last-child {
    vertical-align: middle;
  }
}

.label-form-group {
  font-weight: 500;
  font-size: 17px;
  color: #344357;
}

input[type="radio"] {
  width: 16px;
  height: 16px;
}

.custom-checkbox-left-label {
  padding-left: 0;
}

.custom-label-left {
  &:before,
  &:after {
    right: -2.25rem;
    left: unset;
  }
}

.accordion-item {
  border: solid;
  border-color: #b7c2d0;
  border-radius: 8px;
  padding: 10px;

  .title {
    color: #b7c2d0 !important;
    cursor: default;
  }

  &.active {
    border-color: #854fff;

    .title {
      color: #526484 !important;
      cursor: pointer;
    }

    .accordion-icon {
      cursor: pointer;
    }
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .accordion-head {
    display: flex;
  }
}

.table-wrapper {
  // overflow: auto;
  width: 100%;

  tbody td {
    vertical-align: middle;
  }

  td .react-select__control {
    padding-right: 30px;

    .react-select__value-container {
      padding-right: 0;

      .react-select__input-container {
        font-size: 13px;
      }
    }
  }
}

.react-select__menu-portal {
  z-index: 2000 !important;
}

td .form-group-wrapper {
  padding: 0;
}

// .rdt_TableRow {
//   &:hover {
//     .nk-tb-action-hidden {
//       opacity: 1;
//     }
//   }
// }

.rdt_TableRow {
  &:hover {
    background: #f8f9fc;
    // box-shadow: 0 2px 15px -4px rgba(#854fff, 0.4);

    .nk-tb-action-hidden {
      border-radius: 40%;
      background: #f8f9fc;
      opacity: 1;
    }
  }
}

.date-picker-portal-menu {
  .react-datepicker-popper[data-placement^="bottom"] {
    transform: translate(0px, 10.25px) !important;
  }

  z-index: 2000 !important;
}

.react-datepicker-popper {
  z-index: 11;
}

.custom-link {
  color: #31b9ce;
}

.required-asterisk {
  color: #e85347;
}

.form-control-wrap {
  ~ .invalid {
    color: #e85347;
    font-size: 11px;
    font-style: italic;
  }
}

.form-control-select {
  ~ .invalid {
    color: #e85347;
    font-size: 11px;
    font-style: italic;
  }
}

.nk-menu-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.time-range-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & ~ .invalid {
    color: #e85347;
    font-size: 11px;
    font-style: italic;
  }
}

.form-control:disabled {
  background-color: hsl(0, 0%, 95%);
}

.form-icon.form-icon-left {
  z-index: 1;

  ~ .react-datepicker-wrapper {
    .form-control {
      padding-left: calc(1rem + 24px);
    }
  }
}

.react-flag-select-wrapper {
  display: inline-flex;
  width: auto;
  padding: 0.4375rem 1rem !important;
  padding-right: 0px !important;
  // padding: 0px !important;
  // padding: 0.4375rem 1rem !important;
  // padding: 15px 0px !important;
  border-radius: 0px !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-right: 0px;
  // z-index: 10;

  ~ input {
    border-left: 0px;
    border-radius: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  ul {
    min-width: 250px;
  }
}

.table-invalid {
  color: #e85347;
  font-size: 14px;
  font-style: italic;
}

.custom-link-text {
  color: #854fff;
  cursor: pointer;
}

.form-label {
  font-size: 16px;

  ~ .form-control-wrap {
    input,
    textarea {
      font-size: 14px;
    }
  }
}

.focus-form {
  color: #3c4d62;
  background-color: #fff;
  border-right-color: transparent !important;
  border-color: #854fff;
  outline: 0;
  box-shadow: -3px 0 0 3px rgb(133 79 255 / 10%);
}

.form-des-block-note {
  background-color: rgb(225 228 231);
  text-align: start;
  color: black;
  border-radius: 30px;
  border: 2px solid;
}

.react-flag-select-button {
  padding: 0px !important;
  // font-size: 12px !important;
  display: block;
  // width: 100%;
  // height: calc(2.125rem + 2px);
  // padding: 0.4375rem 1rem;
  // font-size: 0.8125rem !important;
  font-size: 14px !important;
  font-weight: 400;
  // line-height: 1.25rem;
  color: #3c4d62;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px !important;
  border-radius: 0px !important;
  width: max-content !important;
  // width: 50vh !important;
  // max-width: 50vh !important;
  padding-right: 10px !important;
  border-right: thin solid rgba(77, 77, 77, 0.3) !important;
  // border: 1px solid #dbdfea;
  // border-radius: 0px !important;
  // border-top-left-radius: 4px !important;
  // border-bottom-left-radius: 4px !important;

  &:focus {
    outline: 0px !important;
  }

  // &::after {
  //   content: "";
  //   border: thin solid;
  // }
}

.btn-alt {
  color: #000;
  background-color: #bda0ff;

  &:hover {
    color: #000;
    background-color: #b282fc;
  }
}

.react-datepicker-wrapper {
  & ~ .invalid {
    color: #e85347;
    font-size: 11px;
    font-style: italic;
  }
}

.react-select-flag-container {
  cursor: pointer !important;
  color: #526484 !important;
  border: 1px solid #dbdfea !important;
  padding: 2px 8px !important;
  border-radius: 4px;

  button {
    border: none;
    margin: 2px;
    padding: 2px 0px;
    outline: none;

    &[aria-expanded="true"]:after,
    &:after {
      content: none;
    }

    span {
      font-size: 13px !important;
      line-height: 1.25rem;
      font-weight: 400;
      // margin: 3px 0;
      color: #526484 !important;
      padding: 0;
      margin-right: 0.5rem;
    }
  }
}

.react-select-flag-button {
  border: none;
  margin: 2px;
  padding: 2px 0px;
  outline: none;
}

.react-select-flag-value {
  font-size: 13px !important;
  line-height: 1.25rem;
  font-weight: 400;
  margin: 3px 0;
  color: #526484 !important;
}

.form-group-wrapper {
  display: grid;
  align-items: end;
}

.no-data-box {
  border: 1px solid #e5e9f2;
}

.tab-header-fixed {
  z-index: 10;
  visibility: visible;
  opacity: 1;
  // transition: opacity 2s linear;
  margin-top: 65px;
  height: 55px;
  position: fixed;
  background-color: #fff;
  border-bottom: 1px solid #e5e9f2;

  &.d-none {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }
}

.candidate-status {
  padding: 0.35rem;
  border: 1px solid;
  margin-left: 5px;
  border-radius: 8px;
  font-size: 13px;
}

.form-caption {
  font-size: 15px;
  color: #8094ae;
  margin-top: -0.5em;
  margin-bottom: 0.5em;
}

.timeline-steps {
  display: flex;
  // justify-content: center;
  // overflow: auto;
  // flex-wrap: wrap;
}

.timeline-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  // width: 10px;

  // &:not(:first-child):before {
  //   border-top: 0.15rem solid;
  //   content: "";
  //   position: absolute;
  //   top: 0.65rem;
  //   left: 2.3rem;
  //   width: 2.5rem;
  // }

  &:not(:last-child):after {
    border-top: 0.15rem solid;
    content: "";
    position: absolute;
    top: 0.65rem;
    left: 5.8rem;
    width: 8.4rem;
  }

  // &:not(:first-child):before {
  //   border-top: 0.15rem solid;
  //   content: "";
  //   position: absolute;
  //   top: 0.65rem;
  //   right: 5.8rem;
  //   width: 4.2rem;
  // }

  &.last-active-stage:after {
    border-top: 0.15rem dashed;
    width: 8.4rem;
  }

  &:not(.completed) {
    &::after {
      border-top: 0.15rem dashed;
      width: 8.4rem;
    }

    // &::before {
    //   border-top: 0.15rem dashed;
    // }
  }

  &:not(.completed):not(.skipped) {
    .timeline-status {
      background-color: #fff;
      border: 1px dashed;
    }
  }

  &.skipped {
    .timeline-status {
      background-color: #e0e0e0;
    }
  }

  .timeline-status {
    height: 1.3rem;
    width: 1.3rem;
    flex-shrink: 0;
    margin-top: 2px;

    // &.completed:after {
    //   content: "";
    //   display: block;
    //   width: 0.35em;
    //   height: 0.7em;
    //   border: solid white;
    //   border-width: 0 0.17em 0.17em 0;
    //   position: absolute;
    //   left: 0.55em;
    //   top: 20%;
    //   margin-top: -0em;
    //   -webkit-transform: rotate(45deg);
    //   -moz-transform: rotate(45deg);
    //   -o-transform: rotate(45deg);
    //   transform: rotate(45deg);
    // }

    // &:not(.completed):not(.skipped) {
    //   background-color: #fff;
    //   border: 1px dashed;
    // }
  }

  .is-outline {
    &:after {
      height: 1rem;
      width: 1rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.timeline-content {
  min-width: 10rem;
  width: 10rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-top: 0.5rem;
}

// .react-datepicker-wrapper {
//   + .form-icon {
//     z-index: 1000;
//   }
// }
// .nk-tb-action-hidden {
//   .rdt_TableRow:hover & {
//     opacity: 1;
//   }
// }

// body {
//   margin-top: 20px;
// }
// .timeline-steps {
//   display: flex;
//   justify-content: center;
//   flex-wrap: wrap;
// }

// .timeline-steps .timeline-step {
//   align-items: center;
//   display: flex;
//   flex-direction: column;
//   position: relative;
//   margin: 1rem;
// }

// @media (min-width: 768px) {
//   .timeline-steps .timeline-step:not(:last-child):after {
//     content: "";
//     display: block;
//     border-top: 0.25rem dotted #3b82f6;
//     width: 3.46rem;
//     position: absolute;
//     left: 7.5rem;
//     top: 0.3125rem;
//   }
//   .timeline-steps .timeline-step:not(:first-child):before {
//     content: "";
//     display: block;
//     border-top: 0.25rem dotted #3b82f6;
//     width: 3.8125rem;
//     position: absolute;
//     right: 7.5rem;
//     top: 0.3125rem;
//   }
// }

// .timeline-steps .timeline-content {
//   width: 10rem;
//   text-align: center;
// }

// .timeline-steps .timeline-content .inner-circle {
//   border-radius: 1.5rem;
//   height: 1rem;
//   width: 1rem;
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   background-color: #3b82f6;
// }

// .timeline-steps .timeline-content .inner-circle:before {
//   content: "";
//   background-color: #3b82f6;
//   display: inline-block;
//   height: 3rem;
//   width: 3rem;
//   min-width: 3rem;
//   border-radius: 6.25rem;
//   opacity: 0.5;
// }
